<template>
  <v-menu
    v-if="editable"
    v-model="showMenu"
    ref="menu"
    transition="scale-transition"
    min-width="auto"
    offset-y
    :return-value.sync="date"
    :close-on-content-click="false"
  >
    <!-- Input -->
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        prepend-inner-icon="mdi-calendar"
        placeholder="날짜 선택"
        solo
        flat
        dense
        clearable
        readonly
        outlined
        hide-details
        v-bind="attrs"
        v-on="on"
        :error-messages="dateError"
        @input="v$.date.$touch()"
        @blur="v$.date.$touch()"
      ></v-text-field>
    </template>
    <!-- Date Picker-->
    <v-date-picker
      v-model="date"
      no-title
      scrollable
      :day-format="formatDay"
      @input="picked => $refs.menu.save(picked)"
    />
  </v-menu>
  <div v-else class="d-flex align-center">
    <v-icon small class="mr-1">mdi-calendar</v-icon>
    <span>{{ date }}</span>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { requiredIf } from "@vuelidate/validators";

export default {
  name: "DateFieldItem",
  setup: () => ({ v$: useVuelidate() }),
  props: {
    value: {
      type: Object,
      description: "필드 데이타 (v-model)"
    },
    templateFrame: {
      type: Object,
      required: true,
      description: "필드 속성"
    },
    editable: {
      type: Boolean,
      default: false,
      description: "편집 모드"
    }
  },
  data: () => ({
    showMenu: false
  }),
  computed: {
    date: {
      get() {
        return this.value?.date ?? "";
      },
      set(val) {
        this.$emit("input", { date: val });
      }
    },
    dateError() {
      const { $dirty, required } = this.v$.date;
      const errors = [];
      if (!$dirty) return errors;
      if (required.$invalid) errors.push("필수 입력사항 입니다.");
      return errors;
    }
  },
  methods: {
    // 달력 '일' 표시 포맷팅
    formatDay(val) {
      return Number(val.substr(val.lastIndexOf("-") + 1));
    },
    async validate() {
      return await this.v$.$validate();
    }
  },
  validations() {
    return {
      date: {
        required: requiredIf(this.templateFrame?.required)
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      // 기본값 초기화 (수정일 경우 기존값 할당)
      this.date = this.value?.date ?? "";
    });
  }
};
</script>

<style lang="scss" scoped></style>
